import React from 'react'

class BuildingSpecifications extends React.Component {
  render() {
      return <div label="Building Specifications">
       Year Built: 1978<br/>
Conversion to Condominiums: 1990<br/>
Last Major Renovation: 1990<br/>
Builder:<br/>
Floor Plans:<br/>
Total number of Units: 78<br/>
Assigned Parking: 1-level, subterranean garage.  159 spaces<br/>
Visitor Parking: Street (3 day limit).  3 garage compact spaces w/reservation - fee applies) Contact Manager<br/>
Additional Storage: Approved Storage in some (not all) parking spaces<br/>
Bike Storage: Allowed in parking space<br/>
Trash Room & Recycling: Yes - Directly under building 1, 2 and behind lobby in garage.  Trash shoots on every floor<br/>
(Oversized items require special pick-up-fee applies)<br/>
Trash Chutes: Yes (every floor)<br/>
HVAC:<br/>
Security: Video Surveillance<br/>
Utilities: Gas, Water (included in monthly HOA dues)<br/>
Cable: Yes<br/>
DirecTV or Comcast Cable<br/>
Master Antenna/Satellite: Yes, DirecTV (Contact Manager for package and pricing)<br/>
Pets*: (refer to schedule of fees) 1 dog or 2 cats<br/>
(Pet Registration fee applies per household) See Pet Policy<br/>
Management Company: HOA Organizers<br/>
New Resident Orientation: Contact Manager<br/>
Wi-Fi: Lobby Level Only<br/>
In-House Maintenance: No (you can ask Mgmt for vendors used)<br/>
Board Meetings: Monthly<br/>
Move-in/Move-out: (refer to schedule of fees) Contact Manager (Procedures & fees must be followed)<br/>
Lease Agreement & Addendum: Contact Manager<br/>
Spa: Heated Year around<br/>
Pool: Heated, May to October<br/>
Roof: Pitched and flat<br/>
       </div>
  }
}

export default BuildingSpecifications