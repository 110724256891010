import React,{Component} from "react";
//import Thumbnail from '../Components/Thumbnail'

const baseUrl = "https://www.tolucahillshoa.com";

class BreakIns extends Component {
    constructor(props) {
      super(props)
      this.state = { 
        visible:false,
        activeInsidentId:0,
        activeImageId:0,
        activeThumb:'',
        activeThumbId:'',
        imageURL:'',
        scrollPosition: window.pageYOffset
       }
    }
    insidents = [
        {id:6,folder:'Jan8', imagecount:15, thumbs:[], insidentDate:'Jan 8 2017', insidentDescription:'This theif arrives in a brand new black Ford Torres, no license plate at 7:49am and parked. He entered the building at 7:57am and started steeling mail at 7:58:39am a home owner interuped him 4 minutes later. He took mail from 10 out of 14 mail banks.'},
        {id:5,folder:'Dec5', imagecount:13, thumbs:[], insidentDate:'Dec 5 2016', insidentDescription:'These thieves wore ski masks but looking at the what they did expose, I believe this is the same guy from Oct 22 and Oct 23 with the Red Nissan 300z and maybe the guy with the white hat from Oct 22. I did not get a good shot of the 3rd person. They were driving a newer black Chevy Camaro'},
        {id:4,folder:'Nov16', imagecount:13, thumbs:[], insidentDate:'Nov 16 2016', insidentDescription:'This theif took her time and would go sort the stolen mail in the lobby sitting on the couch while residents came to check thier mail and then return to steeling when the residents cleared out of the lobby.'},
        {id:3,folder:'Oct23', imagecount:11, thumbs:[], insidentDate:'Oct 23 2016', insidentDescription:'The first thief came at 8:05 AM. He was driving a black Mercedes with an Uber tag on the back window, the second thief was driving a Red 300z and returned from the day before.'},
        {id:2,folder:'Oct22', imagecount:3, thumbs:[], insidentDate:'Oct 22 2016', insidentDescription:'The first thief finished taking the contents of Unit 124 mail, the 2nd thief came to clear out all of the boxes but left when a resident entered the lobby.'},
        {id:1,folder:'Oct21', imagecount:2, thumbs:[], insidentDate:'Oct 21 2016', insidentDescription:'This theif attempted to break in Unit 124 mail box 5 times. This is the same thief from Oct 7'},
        {id:0,folder:'Oct7', imagecount:27, thumbs:[], insidentDate:'Oct 7 2016', insidentDescription:'This theif visited 3 times.'}
        ];


        showenlargePic = (sender, e) => {
            window.scrollTo(0, 0);
            this.setState({
                visible:true,
                activeInsidentId: sender.insidentIndex,
                activeImageId:sender.key.split('-')[1],
                activeThumbId:sender.key,
                imageURL:sender.imageURL,
                activeThumb:sender.thumbSrc,
                scrollPosition:e.currentTarget.offsetTop
            })
        }

    getImageUrl = (folder, imageId) => {
        let imageSrc = baseUrl + '/images/mailtheives/' + folder + '/mailthief';        
        if(imageId < 10)
        {
            imageSrc += '0' + imageId + '.jpg';
        }
        else
        {
            imageSrc += imageId + '.jpg';
        }
        return imageSrc;
    }
    hidePicture =() =>
    {
        document.getElementById(this.state.activeThumbId).scrollIntoView();
         this.setState({
             visible:false
         })

    }
    previousPicture =() =>
    {
        let InsidentId = this.state.activeInsidentId;
        let currentImageId = this.state.activeImageId;
        //if the current image is not the first image in an array, then decrement the current image.
        if(currentImageId === 0)
        {
            if(InsidentId === 0) //set current image to last image in the last set
            {
                InsidentId = this.insidents.length -1;
                currentImageId = this.insidents[InsidentId].imagecount-1; 
            }
            else
            {
                //set the current image to the last image in the previous set.
                InsidentId--;
                currentImageId = this.insidents[InsidentId].imagecount-1;			
            }
        }
        else
        {
            
            currentImageId--;
        }
        
        this.setState({
            activeInsidentId:InsidentId,
            activeImageId:currentImageId,
            activeThumbId:InsidentId+'-'+currentImageId,
            imageURL: this.insidents[InsidentId].thumbs[currentImageId].imageURL,
            activeThumb:this.insidents[InsidentId].thumbs[currentImageId].thumbSrc
        })
    }
    nextPicture =() =>
    {
        let InsidentId = this.state.activeInsidentId;
        let currentImageId = this.state.activeImageId;
        if(currentImageId < this.insidents[InsidentId].imagecount-1)
        {
            currentImageId++;
        }
        else
        {
            //if the current insident is not the last image and we have moved past the last image, start the next set of images.
            if(InsidentId <  this.insidents.length -1)
            {
                InsidentId++;
                currentImageId = 0;
            }
            else
            {
                //reset back to the beginning
                InsidentId = 0;
                currentImageId = 0;
            }
        }
        this.setState({
            activeInsidentId:InsidentId,
            activeImageId:currentImageId,
            activeThumbId:InsidentId+'-'+currentImageId,
            imageURL: this.insidents[InsidentId].thumbs[currentImageId].imageURL,
            activeThumb:this.insidents[InsidentId].thumbs[currentImageId].thumbSrc
        })
    }
    handleScroll( scrollTop ) {
        this.setState({ scrollTop });
    }
    
    render() {
        const loadThumbnails = index => {
            
                let data = this.insidents[index];
                if(data.thumbs.length < data.imagecount){
                for (let i = 0; i < data.imagecount; i++) {
                    let thumbSrc = baseUrl + '/images/mailtheives/' + data.folder + '/thumbnails/mailthief';
                    let thumbId = index+'-'+i;
                    thumbSrc += (i < 10)? '0' + i + '.jpg' : i + '.jpg';
                    let imageURL = this.getImageUrl(data.folder, i);
                    data.thumbs.push({ 
                        key:thumbId,
                        id:thumbId,
                        thumbSrc:thumbSrc,
                        imageURL:imageURL,
                        insidentId:data.id,
                        insidentIndex:index
                    });
                }
            }
            }
            const getThumbnails = (insident,index) => {
                let thumbnails = [];                
                insident.thumbs.map((data) => (
                        thumbnails.push(
                        // <Thumbnail
                        //     key={data.key}
                        //     id={data.id}
                        //     classname={this.state.activeThumbId === data.key ? 'activeThumb':'thumbnailHolder'} 
                        //     clickEvent={this.showenlargePic.bind(this,data)}
                        //     thumbSrc={data.thumbSrc}>
                        //     </Thumbnail>
                            <div className={this.state.activeThumbId === data.key ? 'activeThumb':'thumbnailHolder'} 
                             key={data.id} id={data.id} 
                             onClick={this.showenlargePic.bind(this,data)}><img src={data.thumbSrc} alt={data.thumbSrc}/></div>
                            )))
               
               return thumbnails;
                };
for(let i=0;i< this.insidents.length;i++){loadThumbnails(i);}

        return  (
            <div className="tab-content">
                {this.state.visible ? 
            <div id="enlargeDivHolder" className="LargeImageHolder">
                <div id="closeDiv"><img src="https://www.tolucahillshoa.com/images/closebuttonOff.png" alt="close button" onClick={this.hidePicture} /></div>
                <div id="backButtonDiv"><img src="https://www.tolucahillshoa.com/images/backButtonOff.png" alt="Previous Picture" onClick={this.previousPicture} /></div>
                <div id="nextButtonDiv"><img src="https://www.tolucahillshoa.com/images/nextButtonOff.png" alt="Next Picture" onClick={this.nextPicture} /></div>
                <div id="enlargeDiv"><img id="enlargedPicture" src={this.state.imageURL} alt="Mail Thief" onClick={this.nextPicture} /></div>
            </div>
             : null }

            {
            this.insidents.map((data, index) => (
                <div key={index}>
                    <div className='insidentTitle'>{data.insidentDate}</div>
                    <div className='insidentDescription'>{data.insidentDescription}</div>
                    {getThumbnails(data, index)}
                </div>
                
            ))
            
            }
            </div>
        )
  }
}

export default BreakIns