import React from 'react'

class MakingDuesPayments extends React.Component {
  render() {
    return <div className="tab-content"> <p>
    Payment information you will need:<br/>

Management Company ID: <b>4106</b><br/>
Association ID: <b>000TOL</b><br/>
Property Account Number: TOL-<b>[Your Unit Number]</b><br/>
</p>
<p>Toluca Hills HOA has 3 payment options for paying your monthly dues. Click the link below for detailed instructions:<br/>
<ul>
<li>Pay by eCheck</li>
<li>Pay by Credit Card</li>
<li>Pay by mail</li>
</ul>
For mail-in checks:<br />

They need to be made payable to <b>Toluca Hills Apartment Corp</b><br />
They need to have their account number written on them (found on the monthly statements)<br />
<br />
They need to be mailed to:<br />
<b>
Union Bank c/o HOA Organizers<br />
P.O. Box 51126<br />
Los Angeles, CA 90051<br /></b><br />
The instructions to sign up for paperless statements are on the paper statements.
</p>
    </div>
       }
    }
    
    export default MakingDuesPayments