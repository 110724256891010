import React from 'react'

class Movein_Procedures extends React.Component {
  render() {
    return <div className="tab-content"> 
        <p>Moves must be scheduled and coordinated with building management.  For additional details regarding moving and fees, please contact HOA Organizers.</p>
        <p>This is a rental restricted community.   Do to lending and insurance requirements, as well as our obligation to FNMA and FHLMC, rentals are restricted to 20 units at a time, on a first-in, first-out basis.</p>
        <p>MOVE-IN/MOVE-OUT PROCEDURES - DRAFT</p>
        <ol className="AlphaList">
          <li>The Manager must be notified of all change in occupancy, prior to listing and/or renting the unit and after any changes in occupancy.  This is a rental-restricted community.</li>
          <li>All furniture and large objects must be moved in and out of the building through the Barham Garage Gate or South Coyote Canyon Gate. </li>
          <li>Lobby elevator services building 2 & 3.  The Lobby is not for use for storage of materials or tools.  Materials, Furniture and large items are not allowed to be staged in the lobby.  Propping of Glass lobby door is prohibited.</li>
            <ol className="NumberList">
                <li>Residents moving into building 2 and 3 must move items from through garage lobby door to lobby elevator or stairs.  Use and propping of glass lobby door is prohibited for moving.</li>
            </ol>
          <li>Any gate and elevator use related to occupant’s move-in or move-out, shall be subject to a security deposit of $500.</li>
          <ol className="NumberList">
            <li>The security deposit of $500 is payable by Check to Toluca Hills HOA and shall be given to the On-Site Supervisor a minimum of two (2) business days prior to the scheduled move date.</li>
            <li>The security deposit will be returned to the moving party upon completion of moving activities, pending a damage inspection conducted with the On-Site Supervisor or a designee of the Board of Directors.</li>
            <ol className="RomanList">
              <li>If the damage inspection reveals no damage to the building, including the elevator, as a result of moving activities, the security deposit will be returned in full.</li>
              <li>For any damages found as a result of moving activities, the cost of repairing the damage, plus any administrative fees associated with the repair, will be deducted from the security deposit.</li>
              <li>Any damages in excess of the amount of the security deposit will be billed to the moving party in the form of a special assessment, if such charges are not paid in full after demand.</li>
            </ol>
          </ol>
          <li>Elevator walls pads must be in place before moving begins, failure to due so will result in forfeiture of security deposit</li>
          <li>Moving trucks under 35 feet can reserve parking at the South Coyote     Canyon Gate (hillside).  Moving trucks greater than 35 feet must obtain parking permit from the city of Los Angeles.Contact LA City Services at 3-1-1 to purchase temporary parking permits.</li>
          <li>Unit Owners and Tenants may not reserve elevators.</li>
          <li>A moving party is limited to one reservation per day.    There is no charge for scheduling move-in/out on Thursday or Friday when the on-site manager scheduled to be available.  All other days require owner to pay $45/hour for each hour the gates/door are setup for moving.</li>
          <li>Reservations will be accepted (2) weeks or more in advance.</li>
          <li>SECURITY.  Any door propping of exterior doors will result in a $750 fine.  This includes South Coyote Canyon Pedestrian Gate and Lobby Glass door.</li>
          <li>ANY WORK BEING DONE TO YOUR UNIT REQUIRES APPROVAL FROM HOA.  Failure to following rules on architecture and contractors will result in a $1000 fine.   Any and all plumbing work falls under this restriction. See ARC Rules and Regulations for details.  Contact On-site Manager for details.</li>
        </ol>
       </div> 
       }
    }
    
    export default Movein_Procedures