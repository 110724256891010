import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./pages/home";
import Documents from "./pages/documents";
import Contact from "./pages/contact";
import Movein_Procedures from "./pages/Move-in_Procedures";
import BuildingSpecifications from "./pages/BuildingSpecifications";
import MakingDuesPayments from "./pages/MakingDuesPayments";
import BreakIns from "./pages/BreakIns"
import Notfound from "./notfound";
import Header from "./header";
import Footer from "./footer";

const routing = (
  <Router>
  <div id="app">
    <div id="content">
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/documents" component={Documents} />
        <Route path="/contact" component={Contact} />
        <Route path="/Move-in_Procedures" component={Movein_Procedures} />
        <Route path="/Building-Specifications" component={BuildingSpecifications} />
        <Route path="/Making-Dues-Payments" component={MakingDuesPayments} />
        <Route path="/BreakIns" component={BreakIns} />
        <Route component={Notfound} />
      </Switch>
      <Footer />
    </div>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));