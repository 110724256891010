
import React from 'react'

class Documents extends React.Component {
  render() {
    return(
        <div className="tab-content"> 

<p>This section includes relevant documents and forms for the HOA. To view most of the documents you will need a PDF reader which you can obtain 
           for your computer (and you may already have one). You can obtain one from Foxit Software or from Adobe.  
           If you have an Apple computer, you already have the software to read these PDF documents.</p>
           <ul>
<li><a href="https://tolucahillshoa.com/Documents/261%20CC&RS-Searchable.pdf">CC&R</a></li>
<li><a href="https://tolucahillshoa.com/Documents/261%20CC&RS-Searchable.pdf">BY LAWS</a></li>
<li><a href="https://tolucahillshoa.com/Documents/261%20CC&RS-Searchable.pdf">ARTICLES OF INCORPORATION</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Toluca%20Hills%20Rules%20and%20Regulations%20-%20Current%202009.pdf">RULES AND REGULATIONS</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Contractor%20Rules%20-%20June%202010%20-%20ADOPTED.pdf">CONTRACTOR RULES</a></li>

<li><a href="https://tolucahillshoa.com/Documents/ARC%20Procedures%20-%20June%202010%20-%20ADOPTED.pdf">ARCHITECTURAL POLICY AND PROCEDURES</a></li>
<li><a href="https://tolucahillshoa.com/Documents/ARC%20Guidelines%20-%20ADOPTED.pdf">ARCHITECTURAL GUIDELINES</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Contractor%20Info%20and%20Acklnowledgement%20Form.pdf">CONTRACTOR ACKNOWLEDGEMENT FORM</a></li>
<li><a href="https://tolucahillshoa.com/Documents/ARC%20Homeowner%20Checklist.pdf">HOMEOWNER CHECKLIST AND FAQ</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Arc_approval_form.pdf">ARCHITECTURAL APPROVAL FORM</a></li>

<li><a href="https://tolucahillshoa.com/Documents/ARC%20Procedures%20-%20June%202010%20-%20ADOPTED.pdf">ARCHITECTURAL AND DESIGN POLICY AND PROCEDURES</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Toluca%20Hills%20Rules%20and%20Regulations%20-%20Current%202009.pdf">POOL RULES</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Toluca%20Hills%20Rules%20and%20Regulations%20-%20Current%202009.pdf">PARKING</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Toluca%20Hills%20Rules%20and%20Regulations%20-%20Current%202009.pdf">PETS</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Toluca%20Hills%20Rules%20and%20Regulations%20-%20Current%202009.pdf">FINES AND ASSESSMENT COLLECTION</a></li>
<li><a href="{{PAYMENTADDRESSANDAUTOMATICPAYMENT}}">PAYMENT ADDRESS AND AUTOMATIC PAYMENT</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Homeowner_Contact_Information.pdf">RESIDENT INFORMATION FORM (REQUIRED)</a></li>
<li><a href="https://tolucahillshoa.com/Documents/Maintenance_Request.pdf">MAINTENANCE REQUEST FORM</a></li>
</ul>
</div>
    )
 }
}

export default Documents