import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const activeClass = (path, link) => {    
    if (path === link) {
        return true;
    }
    return false;
};

function isActiveTab(tab,pathname)
{
    return activeClass(pathname,tab) ? 'tab-list-item tab-list-active' : 'tab-list-item';
}

function Header() {
    const { pathname } = useLocation();
  return (
    <div className="HeaderHolder">
    <div id="header" className="App-header">
    <div className="managedBy"><a href="http://hoaorganizers.com/new/" className="App-link">Managed by HOA Organizers</a></div>
    <div className="THHOALogo">
      <img src="images/shapeimage_1.png" alt="Toluca Hills" className="App-logo"/><br />
      <span className="tagLine">TOLUCA HILLS HOME OWNERS ASSOCIATION</span>
    </div>
    <div className="building"><img src="images/shapeimage_2.png" alt="exterior building" /></div>
  </div>
    <div className="tabs">
    <ol className="tab-list">
     <li className={(isActiveTab('/',pathname))}>
      <NavLink exact activeClassName="active" to="/">
        Home
      </NavLink>
     </li>
     <li className={isActiveTab('/documents',pathname)}>
      <NavLink activeClassName="active" to="/documents">
        Documents
      </NavLink>
     </li>
     <li className={isActiveTab('/contact',pathname)}>
      <NavLink activeClassName="active" to="/contact">
        Contact
      </NavLink>
     </li>
     <li className={isActiveTab('/Move-in_Procedures',pathname)}>
      <NavLink activeClassName="active" to="/Move-in_Procedures">
      Move-in Procedures
      </NavLink>
      </li>
     <li className={isActiveTab('/Building-Specifications',pathname)}>
      <NavLink activeClassName="active" to="/Building-Specifications">
      Building Specifications
      </NavLink>
      </li>
     <li className={isActiveTab('/Making-Dues-Payments',pathname)}>
      <NavLink activeClassName="active" to="/Making-Dues-Payments">
      Making Dues Payments
      </NavLink>
      </li>
     <li className={isActiveTab('/BreakIns',pathname)}>
      <NavLink activeClassName="active" to="/BreakIns">
      Break Ins
      </NavLink>
      </li>
     </ol>
    </div>
    </div>
  );
}
export default Header;