import React from 'react'

class ContactUs extends React.Component {
  render() {
    return <div className="tab-content"> 
    <p>The Toluca Hills Homeowners Association has a 5-person Board of Directors and is in partnership with HOA Organizers Property Management for community management.</p>
     <p>For Property Manager: General Service, Questions and Requests, please contact:
     Matt Meadors<br/>
     matt@hoaorganizers.com<br/>
     HOA Organizers<br/>
     20847 Sherman Way, Penthouse<br/>
     Winnetka, CA 91306<br/>
     Office# (818) 778-3331<br/>
     Direct Line#: (747) 247-4517<br/>
     Fax# (818) 286-9434<br/>
     <a href="https://www.hoaorganizers.com" target="_blank" rel="noreferrer">www.hoaorganizers.com</a><br/>
     </p>
     <p>
     For Emergencies, please call:<br/>
     911 first for all life threatening emergencies (medical/police/fire)<br/>
     323-856-5732 for non-life threatening emergencies to reach the Police Department (LAPD, Hollywood Division)<br/>
     </p>

     <p>COMMUNICATION TO THE HOMEOWNERS ASSOCIATION CAN BE MADE IN WRITING TO:<br/>
     Toluca Hills Homeowners Association<br/>
     3480 Barham Blvd, Suite B<br/>
     Los Angeles, CA 90068<br/>
     </p>
     <p>
     The Association has monthly meetings located in the community (main lobby) available for Toluca Hills Homeowners to attend 
     (and we encourage homeowner attendance).  
     </p>
     <p>
     For emergencies, please contact 911.  For non-life threatening emergencies, please contact the Hollywood Sheriff’s office at (323) 856-5732
     </p>    


    </div> 

  }
}

export default ContactUs