import React from 'react'
class Home extends React.Component {
  render() {
    return (
        <div className="tab-content"> 
        <h2>Welcome to Hollywood Hills, CA</h2>
        <p>This is the official website of the Toluca Hills Homeowners Association.  We hope this website is a place where you can find important announcements and documents relevant to the community and the HOA. </p>
         <p>Please visit the Documents section for all the official documents for the HOA.  If you are a visitor and considering moving to Toluca Hills, welcome!</p>
         <p>Be sure to pick up your mail and packages as soon as you can. The HOA has had many issues with package and mail theft in the lobby. <br/><a href="https://tolucahillshoa.com/mailthieves.html">See pictures of past mail theft events</a></p>
        </div> 
    )
  }
}

export default Home